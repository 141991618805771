import React from 'react';
import { graphql } from 'gatsby';
import { Rows, SiteMetadata, Button, ButtonsContainer, TypeButtons } from '../components';
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { Layout } from '../layouts/Layout';

export default ({ data, ...props }) => {
  return (
    <Layout>
      <SiteMetadata
        title="Streamings on Amazon Prime Video"
        description="Theatre related films and recordings streamings available on Amazon Prime"
        path={props.location.pathname}
      />
      <div className="mt-8 mx-4 text-indigo-800 text-base">
        <h2 className="text-2xl font-bold text-secondary mb-1 text-indigo-600">
          Streaming Theatre-related Films on Amazon Prime
          </h2>
        <h3 className="text-gray-600 mb-6">
          Free streaming for Amazon Prime Members
          </h3>
        <p className="mt-4">
          Amazon Prime is a great source of theatre related films and
          recordings, and below you'll find a selection of them ready to
          stream with a Prime membership.
          </p>
        <p className="mt-4 mb-4 lg:mt-2">
          If you don't have a Prime membership, now might be a great time to
          start a 30-day free trial. You won't pay anything so long as you
          cancel your subscription within the trial period.
          </p>

        <OutboundLink
          target="_blank"
          rel="noreferrer noopener"
          href="https://www.amazon.co.uk/gp/video/offers/ref=atv_pv_new_offer/261-6142526-7006845?ie=UTF8&linkCode=ur1&tag=theshowsmustgoonline-21"
          className="font-bold underline"
        >
          Start your free 30-day Amazon Prime trial now
          </OutboundLink>

        <p className="mt-2 text-sm">
          To keep us running, our site gets a small commission from Amazon if
          you sign up or purchase anything via our links.
          </p>
      </div>
      <Rows nodes={data.includedInPrime.nodes} />
      <ButtonsContainer>
        <Button to="/platforms/amazon/prime-video?ref=amazon-button">
          See More >>
          </Button>
      </ButtonsContainer>

      <div className="mt-8 mx-4 text-indigo-800 text-base">
        <h2 className="text-2xl font-bold text-secondary mb-1 text-indigo-600">
          Theatre-related Films Avaialble for Rent or Purchase on Amazon Prime
        </h2>
        <h3 className="text-gray-600 mb-6">
          Available for non-Amazon Prime member and Amazon Prime member
          </h3>
        <p className="mt-4">
          There are more theatre related movies and recordings available on Amazon Video, for a small fee to rent or to purchase.  It is still a very good way to spend an evening renting a show from Amazon.
          </p>
        <p className="mt-4 mb-4 lg:mt-2">
          You do not need a Amazon Prime membership in order to rent or buy these titles.
          </p>
      </div>
      <Rows nodes={data.rentable.nodes} />
      <ButtonsContainer>
        <Button to="/platforms/amazon/prime-video-paid?ref=amazon-button">
          See More >>
          </Button>
      </ButtonsContainer>
      <TypeButtons title="You might also be interested in..."></TypeButtons>
    </Layout>
  );
};

export const query = graphql`
  query AmazonPlatformQuery($filmTabeName: String!) {
    includedInPrime: allAirtable(
      filter: {
        table: { eq: $filmTabeName }
        data: { category: { eq: "Included In Prime Video" } }
      }
      limit: 6
      sort: { fields: data___createdAt, order: DESC }
    ) {
      nodes {
        data {
          tags
          image {
            ...HorizontalCardImageFragment
          }
          name
          url
          summary
        }
      }
    }
    rentable: allAirtable(
      filter: {
        table: { eq: $filmTabeName }
        data: { category: { eq: "Rentable" } }
      }
      limit: 6
      sort: { fields: data___createdAt, order: DESC }
    ) {
      nodes {
        data {
          tags
          image {
            ...HorizontalCardImageFragment
          }
          name
          url
          summary
        }
      }
    }
  }
`;
