import React from 'react';
import { Footer, VectorHero, Navigation, EmailCollection, LinksFooter } from '../components';

export const LayoutFull = ({ children, title, category }) => {
  const defaultDesc =
    'Theatre streamings and non-bootleg recordings that are being offered online for free, or for a small donation fee, during the COVID-19 pandemic';
  return (
    <>
      {/* <Sumo /> */}
      <Navigation />
      <VectorHero
        title={title}
        category={category}
        description={defaultDesc} />
      <div className="container">
        {children}
      </div>
      <EmailCollection />
      <LinksFooter />
      <Footer />
    </>
  );
};
